.hidden {
  opacity: 0;
}

.polarisInputButton {
  position: absolute;
}

.inputButton {
  z-index: 1;
  width: 100%;
  height: 100%;
  margin-left: -29%;
  margin-top: -3%;
  position: absolute;
}